<template>
    <AWeekPicker
        style="width:100%;"
        placeholder="Pilih Minggu Ke-"
        allow-clear
        v-model:value="valueState">
    </AWeekPicker>
</template>

<script>
import { useVModel } from '@/components/useVModel.js'

export default {
    props: {
        value: {
            type: [Array, Number, String],
            default: () => null,
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        return {
            valueState: useVModel(props, 'value'),
        }
    },
}
</script>